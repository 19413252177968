import { graphql } from 'gatsby'
import React from 'react'

import { JoinUsPageContent } from '../../@import-types/contentful/JoinUsPage.types'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import VideoHero from '../../components/_heroes/VideoHero'
import Colors from '../../constants/Colors'
import { RootPageQuery } from '../../graphql/global.graphql'

type JoinUsPageProps = RootPageQuery<{
  contentfulJoinUsPage: JoinUsPageContent
}>
export default function JoinPage({ data, pageContext }: JoinUsPageProps) {
  const { contentfulJoinUsPage } = data

  const { title, heroSubtitle, heroImage, content, heroPoster } =
    contentfulJoinUsPage

  return (
    <Layout
      data={data}
      basePageContent={contentfulJoinUsPage}
      pageTextColor={Colors.White}
      pageContext={pageContext}
    >
      {title && (
        <VideoHero
          title={title}
          description={heroSubtitle}
          media={heroImage}
          poster={heroPoster}
        />
      )}

      {content && <ContentArea content={content} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query JoinPageById($id: String!, $startPageId: String!, $locale: String!) {
    contentfulJoinUsPage(id: { eq: $id }) {
      ...BasePageFragment

      title

      heroSubtitle

      heroImage {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          contentType
          url
        }
      }

      heroPoster {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }
    }

    ...GlobalFragment
  }
`
